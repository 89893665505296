@import 'global';

    @media screen and (max-width: 950px) {
      .Banner {
        height: 650px;
        max-height: 800px;
      }
    }

    @media screen and (min-width: 951px) {
      .Banner {
        height: 100vh;
        max-height: 750px;
      }
    }

.Banner
{
    position: relative;

    .Media
    {
        background-color: white;
        height: 100%;
        overflow: hidden;

        background-position: initial; // this will center the image so it shows it from the top instead of from the center

        position: relative;

        .MediaCaption
        {
            text-overflow: ellipsis;
            text-align: center;

            position: absolute;
            bottom: 0;

            color: white;
            opacity: 0.9;

            width: 100%;
            max-height: 60%;
            min-height: 15%;

            font:
            {
                size: $header-font-size;
                weight: 200;
            }

            transition: 300ms;
            cursor: pointer;
            &:hover
            {
                opacity: 0.8;
            }

        }

        transition: 300ms;
        cursor: pointer;
        &:hover
        {
            filter: brightness(115%);
        }
    }

    .BannerGrid
    {
        height: 100%;
        position: relative;
    }

    .Content
    {
        color: white;
        height: 100%;
        position: relative;
        cursor: pointer;
        padding: 30px;
        transition: 300ms;

        &:hover, &:active
        {
            .ViewButton
            {
                background-color: $almost-white;
                color: $deep-orange
            }
        }

        .Title
        {
            font-size: $huge-font-size;
            font-weight: 500;
        }

        .Caption
        {
            margin-top: 10px;
            font-size: $bigger-font-size;
        }

        .ViewButton
        {
            margin-top: 40px;
            color: white;

            font-size: $header-font-size;
            border: 3px solid white;
            text-transform: capitalize;

            transition: 200ms;
        }
    }

    .eventColor {
        background-color: $event-color;
        &:hover, &:active {
            background-color: $event-color-active;
        }
    }

    .birthdayColor {
        background-color: $birthday-color;
        &:hover, &:active {
            background-color: $birthday-color-active;
        }
    }

    .courseColor {
        background-color: $course-color;
        &:hover, &:active {
            background-color: $course-color-active;
        }
    }

    .assemblyColor {
        background-color: $assembly-color;
        &:hover, &:active {
            background-color: $assembly-color-active;
        }
    }
}

